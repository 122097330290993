import axios from 'axios'
import Swal from 'sweetalert2'

export default async function Axios(endpoint, json, callback, isNotValid) {

    console.log('');

    axios.post(`${endpoint}`, json)
        .then((res) => {
            if (res.data.status || res.status) {
                if (callback) {

                    switch (res.data.source ? res.data.source : '') {
                        case "NeedFullDataResponse":
                            callback(res.data.data)
                            break;
                        case "AddressLookup":
                            callback(res.data.data ? res.data.data : [])
                            break;
                        case "HcpSearchResponse":
                            callback(res.data.data)
                            break;
                        case "ExUsSearchResponse":
                            callback(res.data.data)
                            break;
                        case "ExUsDetailResponse":
                            callback(res.data.data)
                            break;
                        case "HcoSearchResponse":
                            callback(res.data.data)
                            break;
                        case "WorkflowResponse":
                            callback(res.data.data)
                            break;
                        case "AdminUserSearch":
                            callback(res.data.data)
                            break;
                        case "HcpHome":
                            callback(res.data.data ? res.data.data : [])
                            break;
                        case "HcoHome":
                            callback(res.data.data ? res.data.data : [])
                            break;
                        case "TargetingGetSourceData":
                            callback(res.data.data)
                            break;
                        case "TargetingGetAllExercises":
                            callback(res.data.data)
                            break;
                        case "TargetingGetMyExercise":
                            callback(res.data.data[0])
                            break;
                        case "GetCustomColumnsResponse":
                            callback(res.data.data[0])
                            break;
                        case "workflow":
                            callback(res.data.data)
                            break;
                        case "myworkflow":
                            callback(res.data)
                            break;
                        case "workflowstatus":
                            callback(res.data)
                            break;
                        case "workflowdetail":
                            callback(res.data)
                            break;
                        default:
                            if (Array.isArray(res.data.data) && res.data.data.length == 1) {
                                callback(res.data.data[0])
                            }
                            else {
                                if (Array.isArray(res.data)) {
                                    callback(res.data)
                                }
                                else {
                                    callback(res.data.data)
                                }
                            }
                    } 
                }
            }
            else {
                if (isNotValid) {
                    isNotValid(res)
                }
            }
        })
        .catch((error) => {
            console.error(error);
        });
}

// Add a 401 response interceptor
//axios.interceptors.response.use(function (response) {
//    return response;
//}, function (error) {
//        if (401 === error.response.status
//            && error.response.headers["www-authenticate"] ===
//            'Bearer error="invalid_token", error_description="The token expired"') {
//        Swal.fire({
//            title: "Session Expired",
//            text: "Your session has expired. Would you like to be redirected to the login page?",
//            type: "warning",
//            showCancelButton: true,
//            confirmButtonColor: "#5052bb",
//            confirmButtonText: "Yes",
//            closeOnConfirm: true
//        }).then(async function () {
//            window.location.href = '/authentication/logout';
//        });;
//    } else {
//        return Promise.reject(error);
//    }
//});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        Swal.fire({
            title: "Session Expired",
            text: "Your session has expired. Please login again.",
            showCancelButton: false,
            confirmButtonColor: "#5052bb",
            confirmButtonText: "Yes"
        }).then(async function () {
            window.location.href = '/authentication/logout';
        });;
    } else {
        return Promise.reject(error);
    }
});