import React, { Component } from 'react';
import styles from '../css/SearchTopBar.module.css';
import Set from '../../../components/Set'
import WiggleButton from '../../../components/WiggleButton'
import hcp from '../../../lookup/hcp.json'
import hco from '../../../lookup/hco.json'

class SearchTopBar extends Component {
    constructor(props) {
        super(props);
        if (props.savedSearchParams !== undefined) {
            this.state = {
                search: {
                    FIRST_NAME: this.props.savedSearchParams.FIRST_NAME,
                    LAST_NAME: this.props.savedSearchParams.LAST_NAME,
                    CITY: this.props.savedSearchParams.CITY,
                    STATE: this.props.savedSearchParams.STATE,
                    ZIP: this.props.savedSearchParams.ZIP,
                    PROF_ID: this.props.savedSearchParams.PROF_ID,
                    NPINUM: this.props.savedSearchParams.NPINUM,
                    DEANUM: this.props.savedSearchParams.DEANUM
                },
                filter: {
                    UNIVERSE: this.props.savedSearchParams.UNIVERSE,
                    DEGREE: this.props.savedSearchParams.DEGREE,
                    SPECIALTY: this.props.savedSearchParams.SPECIALTY,
                    TARGET: this.props.savedSearchParams.TARGET,
                    COMPLIANCE: this.props.savedSearchParams.COMPLIANCE
                }
            };
        }
        else {
            if (props.type === '/hcp') {
                this.state = {
                    search: {
                        FIRST_NAME: "",
                        LAST_NAME: "",
                        CITY: "",
                        STATE: "",
                        ZIP: "",
                        PROF_ID: "",
                        NPINUM: "",
                        DEANUM: ""
                    },
                    filter: {
                        UNIVERSE: "",
                        DEGREE: "",
                        SPECIALTY: "",
                        TARGET: "",
                        COMPLIANCE: ""
                    }
                };
            }
            else {
                this.state = {
                    search: {
                        ORG_NAME: "",
                        CITY: "",
                        STATE: "",
                        ZIP: "",
                        ORG_ID: "",
                        TIN: ""
                    },
                    filter: {
                        UNIVERSE: ['FPM', 'Dir FPM', 'Sr Dir FPM'].includes(this.props.user.role) ? "INP" : "", 
                        TARGET: "",
                        ORG_TYPE: ""
                    }
                };
            }
        }
    }

    clearSearch() {
        let search = this.state.search
        for (let i = 0; i < Object.keys(search).length; i++) {
            search[Object.keys(search)[i]] = ""
        }
        this.setState({ search: search })
    }

    clearFilter() {
        let filter = this.state.filter
        for (let i = 0; i < Object.keys(filter).length; i++) {
            filter[Object.keys(filter)[i]] = ""
        }
        this.setState({ filter: filter })
    }

    cleanSearchParams() {
        let json = this.state;
        for (var key in json) {
            var item = json[key];
            for (var subkey in json[key]) {
                if (subkey == 'SHOW_ALL') {

                } else{
                  
                    item[subkey] = item[subkey].trim()
                }
            }
        }
        return json
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.type !== this.props.type) {
            this.clearSearch();
            this.clearFilter();
            if (this.props.type === '/hcp') {
                this.setState({
                    search: {
                        FIRST_NAME: "",
                        LAST_NAME: "",
                        CITY: "",
                        STATE: "",
                        ZIP: "",
                        PROF_ID: "",
                        NPINUM: "",
                        DEANUM: ""
                    },
                    filter: {
                        UNIVERSE: "",
                        DEGREE: "",
                        SPECIALTY: "",
                        TARGET: "",
                        COMPLIANCE: ""
                    }
                })
            }
            else {
                this.setState({
                    search: {
                        ORG_NAME: "",
                        CITY: "",
                        STATE: "",
                        ZIP: "",
                        ORG_ID: "",
                        TIN: ""
                    },
                    filter: {
                        UNIVERSE: "",
                        TARGET: "",
                        ORG_TYPE: "",
                        SHOW_INACTIVE: "N"
                    }
                })
            }
        }
    }

    render() {
        let searchbar = []

        let targetOptions11 = {
            "A": "A",
            "B": "B",
            "C": "C",
            "E": "E",
            "NT": "NT"
        }

        let targetOptions31 = {
            "A": "A",
            "B": "B",
            "NT": "NT"
        }

        let targetOptions = {
            "A": "A",
            "B": "B",
            "C": "C",
            "NPP": "NPP",
            "NT": "NT"
        }
        let targetOptions21 = {
            "A": "A",
            "B": "B",
            "C": "C",
            "NT": "NT"
        }

        let targetOptionsTeam = (this.props.user.team == '11') ? targetOptions11 : (this.props.user.team == '31') ? targetOptions31 : targetOptions;
        if (this.props.user.role == 'PRT' || this.props.user.role == 'COMMERCIAL DERM' || this.props.user.isDermAdmin == 'true') { targetOptionsTeam = targetOptions31}
        if (this.props.user.team == '12') { targetOptionsTeam = targetOptions11; }
        if (this.props.user.team == '21') { targetOptionsTeam = targetOptions21; }

        let type = (this.props.type === '/hcp' ? hcp : hco)
        for (let i = 0; i < type.searchParams.length; i++) {
            if (type.searchParams[i].type === "select") {
                searchbar.push(<Set key={i} type={type.searchParams[i].type} user={this.props.user} roles={type.searchParams[i].roles} teams={[]} title={type.searchParams[i].title} value={this.state.search[type.searchParams[i].column]} options={type.searchParams[i].options} setValue={(data) => {
                    let search = this.state.search
                    search[type.searchParams[i].column] = data
                    this.setState({
                        search: search
                    })
                }} />)
            }
            else {
                searchbar.push(<Set key={i} type={type.searchParams[i].type} user={this.props.user} roles={type.searchParams[i].roles} teams={[]} title={type.searchParams[i].title} value={this.state.search[type.searchParams[i].column]} setValue={(data) => {
                    let search = this.state.search
                    search[type.searchParams[i].column] = data
                    this.setState({
                        search: search
                    })
                }} />)
            }
        }

        let filterbar = []
        for (let i = 0; i < type.filterParams.length; i++) {
            if (type.filterParams[i].type === "select") {

                if (type.filterParams[i].title == 'TARGET') {

                    if (this.props.user.team !== '32') {

                        filterbar.push(<Set key={i} type={type.filterParams[i].type} user={this.props.user} roles={type.filterParams[i].roles} teams={type.filterParams[i].teams ? type.filterParams[i].teams : []} title={type.filterParams[i].title} value={this.state.filter[type.filterParams[i].column]} options={targetOptionsTeam} teamOptions={type.filterParams[i].teamOptions} role={this.props.user.role} team={this.props.user.team} setValue={(data) => {
                            let filter = this.state.filter
                            filter[type.filterParams[i].column] = data
                            this.setState({
                                filter: filter
                            })
                        }} />)

                    }

                    


                } else {

                    console.log('d');

                    filterbar.push(<Set key={i} type={type.filterParams[i].type} user={this.props.user} roles={type.filterParams[i].roles} teams={type.filterParams[i].teams ? type.filterParams[i].teams : []} title={type.filterParams[i].title === "SPECIALTY DERM" ? "SPECIALTY" : type.filterParams[i].title} value={this.state.filter[type.filterParams[i].column]} options={type.filterParams[i].options} teamOptions={type.filterParams[i].teamOptions} role={this.props.user.role} team={this.props.user.team} setValue={(data) => {
                        let filter = this.state.filter
                        filter[type.filterParams[i].column] = data
                        this.setState({
                            filter: filter
                        })
                    }} />)

                }


            }
            else {
                filterbar.push(<Set key={i} type={type.filterParams[i].type} user={this.props.user} roles={type.filterParams[i].roles} teams={type.filterParams[i].teams ? type.filterParams[i].teams : []} title={type.filterParams[i].title} value={this.state.filter[type.filterParams[i].column]} setValue={(data) => {
                    let filter = this.state.filter
                    filter[type.filterParams[i].column] = data
                    this.setState({
                        filter: filter
                    })
                }} />)
            }
        }

        return (<div className={styles.TopBar}>
            <div className={styles.Bar}>
                <div className={styles.IconWrapper}>
                    <i className={["fas", "fa-search", styles.Icon].join(' ')}></i>
                </div>
                <form>
                    <div className={styles.row}>
                        {searchbar}
                        <div className={[styles.set, styles.buttonSet].join(' ')}>
                            <WiggleButton required={Object.values(this.state)} icon={<i className={["fas", "fa-search", styles.IconButton].join(' ')}></i>} text='SEARCH' action={() => {
                                this.props.search(this.cleanSearchParams())
                            }} />
                        </div>
                        <div className={styles.Line}></div>
                        <div className={[styles.set, styles.buttonSet].join(' ')}>
                            <button id={styles.clearButton} type="button" onClick={() => {
                                this.clearSearch();
                                this.clearFilter();
                                this.props.clearResults();
                            }}>CLEAR ALL</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className={styles.Bar} id={styles.filterBar}>
                <div className={styles.IconWrapper}>
                    <i className={["fas", "fa-sliders-h", styles.Icon].join(' ')}></i>
                </div>
                <div className={styles.row}>
                    {filterbar}
                    <div className={[styles.set, styles.buttonSet].join(' ')}>
                        <WiggleButton required={Object.values(this.state)} text='APPLY FILTER' action={() => {
                            this.props.search(this.state)
                        }} />
                    </div>
                    <div className={styles.Line}></div>
                    <div className={[styles.set, styles.buttonSet].join(' ')}>
                        <button id={styles.clearButton} onClick={() => {
                            this.clearFilter();
                        }} type="button">CLEAR FILTER</button>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default SearchTopBar;