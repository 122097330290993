import React, {useState, useEffect} from 'react'
import {useParams} from "react-router-dom";
import styles from './css/Search.module.css'
import TopBar from '../../../components/TopBar'
import Results from '../../../components/Results'
import Titles from './components/ResultTitles'
import Pagination from '../../../components/Pagination'
import Spinner from '../../../components/Spinner'
import Axios from '../../../components/Axios'
import Field from '../../../components/Field'
import states from '../../../lookup/states.json'
import ResultHCP from './components/ResultHCP'
import ResultHCO from './components/ResultHCO'
import StatusBar from './components/StatusBar'
import Table from '../../../components/Table'
import SpecialtyLookUpDerm from '../../../globalcomponents/SpecialtyLookUpDerm';

function Search(props) {
    const [config, setConfig] = useState()
    const [id, setId] = useState(props.exercise.ID)
    const [targetexid, settargetId] = useState(props.exercise.TARGETING_EXRCISE_ID)
    const [terrID, setTerrID] = useState('')
    const [data, setData] = useState([])
    const [prevData, setPrevData] = useState([])
    const [uniqueDegrees] = useState(['MD', 'PA', 'NP', 'APRN', 'DO', 'RN', 'PHAR'])
    const [currentPage, setCurrentPage] = useState(0)
    const [resultsPerPage, setResultsPerPage] = useState(20)
    const [totalResults, setTotalResults] = useState(0)
    const [searchParams, setSearchParams] = useState({
        FIRST_NAME: '',
        LAST_NAME: '',
        DEGREE: '',
        SPECIALTY: '',
        CURRENT_TARGET_TYPE: '',
        CITY: '',
        STATE: '',
        TGT: ''
    })
    const [status, setStatus] = useState()
    const [lock, setLock] = useState()
    const [loaded, setLoaded] = useState(false)
    const {idSlug, terrSlug} = useParams()
    const [options, setOptions] = useState()
    const [resetSort, setResetSort] = useState(false)

    let adminExercise = "";

    if ((props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) && Array.isArray(props.exercise)) {
        adminExercise = props.exercise.filter(function (o) { return o.ID == idSlug; })[0];
    }
    else if (props.user.role === "ADMIN" && !Array.isArray(props.exercise)) {
        adminExercise = props.exercise;
    }

    const getTotals = (exTerr, exId) => {
        exTerr = terrID ? terrID : exTerr
        exId = props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31') ? adminExercise.TARGETING_EXERCISE_ID : props.exercise.TARGETING_EXERCISE_ID


        Axios('/api/targeting/get-current-totals', { user: props.user.userName, terr: exTerr, id: exId, team: props.user.role === "ADMIN" ? adminExercise.TEAM : props.exercise.TEAM, role: props.user.role }, (data) => {

          
            setStatus(data[0])
         

        })
    }

    const search = () => {
        setResetSort(true)
        setLoaded(false)

        if (terrID !== '' || (props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31'))  && (id != undefined && id != 0)) {

            let request = {
                ...searchParams,
                id: id,
                terr: terrID,
                type: (props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) ? adminExercise.CUSTOMER_TYPE : props.exercise.CUSTOMER_TYPE,
                USER_NAME: props.user.userName,
                role: props.user.role
            }
            Axios(`/api/targeting/get-source-table-data`, request, (data) => {
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        let customColumns
                        if (data[i].CUSTOM_COLUMNS) {
                            customColumns = JSON.parse(data[i].CUSTOM_COLUMNS)
                        }
                        data[i] = {
                            ...data[i],
                            ...customColumns
                        }
                    }
                    setData(data)
                    setLoaded(true)

                    if (currentPage !== 0) {
                        setCurrentPage(0)
                    }
                }
                else {
                    setLoaded(false)
                }
            })

        }

    }

    const clear = () => {
        setSearchParams({
            FIRST_NAME: '',
            LAST_NAME: '',
            DEGREE: '',
            SPECIALTY: '',
            CURRENT_TARGET_TYPE: '',
            CITY: '',
            STATE: '',
            TGT: ''
        })
    }

    useEffect(() => {
        search()
    }, [searchParams])

    useEffect(() => {
        if (prevData.length === 0 && data !== []) {
            setPrevData(data)
        }
    }, [data, prevData])

    useEffect(() => {
        setTotalResults(data.length)
    }, [data.length])

    useEffect(() => {

        if ((props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) && Array.isArray(props.exercise)) {
            Axios('/api/targeting/approval-check', { ID: adminExercise.ID }, (data) => {
                props.setApproved(data.length > 0)
            });
        }
        
    }, [])

    useEffect(() => {
        if (!props.approved) {
            if (status) {
                if (["ADMIN", "OTS", "OHS", "OCS", "DTS"].includes(props.user.role) && (status.STATUS_ID === 1 || status.STATUS_ID === 2)) {
                    setLock(false)
                }
                else if (["ADMIN", "OAS", "OCS", "DTS"].includes(props.user.role) && (status.STATUS_ID === 3 || status.STATUS_ID === 5)) {
                    setLock(false)
                }
                else if (["ADMIN", "OBD", "DBD"].includes(props.user.role) && (status.STATUS_ID === 4 )) {
                    setLock(false)
                }
                else if (["ADMIN", "OBD", "DBD"].includes(props.user.role) && (status.STATUS_ID === 5)) {
                    setLock(true)
                }
                else if (["OHS", "DTS"].includes(props.user.role) && (status.STATUS_ID === 5)) {
                    setLock(false)
                }
                else {
                    setLock(true)
                }
            }
        }
    }, [status, props.approved])

    useEffect(() => {
        let id = (props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) ? adminExercise.ID : idSlug ? idSlug : props.data ? props.data.ID : props.exercise.ID
        let exid = (props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) ? adminExercise.TARGETING_EXERCISE_ID : idSlug ? idSlug : props.data ? props.data.TARGETING_EXERCISE_ID : props.exercise.TARGETING_EXERCISE_ID
        let terr = terrSlug ? terrSlug : (props.user.terr != "Unassigned") ? props.user.terr : adminExercise.TEAM ? `${adminExercise.TEAM}` : `${props.exercise.TEAM}`
        setId(id)
        settargetId(exid)
        setTerrID(terr)
        getTotals(terr, exid)

        if ((props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) && Array.isArray(props.exercise)) {
            props.setBackUrl(`/targeting/admin/${id}`)
        }
        else if ((props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) && !Array.isArray(props.exercise)) {
            //do nothing here
        }

        setSearchParams({
            ...searchParams
        })
        let tempConfig = JSON.parse(props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')  ? adminExercise.COLUMNS : props.exercise ? props.exercise.COLUMNS : props.data.COLUMNS)
        for (let i = 0; i < Object.keys(tempConfig).length; i++) {
            const element = Object.values(tempConfig)[i]
            if (["Multiple"].includes(element.col)) {
                element.left = true
                element.sortBy = "FIRST_NAME"
            }
            else if (element.col === "CHANGED_TARGET_VALUE") {
                element.sortBy = "TARGET_TYPE"
            }
        }
        setConfig(tempConfig)
        Axios(`/api/admin/targetingexercise/get-demotion-reasons`, { id: id, type: (props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) ? adminExercise.CUSTOMER_TYPE : props.exercise.CUSTOMER_TYPE}, (data) => {
            let newData = []
            data.forEach((temp, i) => {
                newData.push(temp.REASON)
            });
            setOptions(newData)
        })
    }, [])

    let specialtyTeam31Options = [
        <option key="" value=""></option>,
        <option key="ALL" value="ALL">ALL</option>,
        <option key="AO" value="A/O">A/O</option>,
        <option key="DERM" value="DERM">DERM</option>,
        <option key="NPPA" value="NP/PA">NP/PA</option>,
        <option key="PCP" value="PCP">PCP</option>,
        <option key="PED" value="PED">PED</option>
    ]

    if (!config) {
        return (
            <Spinner />
        )
    }
    else {
        let degreeOptions = [<option key="000" value={''}></option>]
        for (let i = 0; i < Object.keys(uniqueDegrees).length; i++) {
            degreeOptions.push(<option key={Object.values(uniqueDegrees)[i]} value={Object.values(uniqueDegrees)[i]}>{Object.values(uniqueDegrees)[i]}</option>)
        }

        let specialtyOptions = [<option key="000" value={''}></option>]
        if (exerciseTeam = "31") {
            specialtyOptions  = specialtyTeam31Options;

        } else {
            for (let i = 0; i < Object.keys(SpecialtyLookUpDerm).length; i++) {
                specialtyOptions.push(<option key={i} value={Object.keys(SpecialtyLookUpDerm)[i]}>{Object.keys(SpecialtyLookUpDerm)[i]} - {Object.values(SpecialtyLookUpDerm)[i]}</option>)
            }
        }

       

        let targetOptions21 = [
            <option key="" value=""></option>,
            <option key="A" value="A">A</option>,
            <option key="B" value="B">B</option>,
            <option key="C" value="C">C</option>,
            //<option key="E" value="E">E</option>,
            //<option key="NPP" value="NPP">NPP</option>,
            <option key="NT" value="NT">NT</option>
        ]

        let targetOptions11 = [
            <option key="" value=""></option>,
            <option key="A" value="A">A</option>,
            <option key="B" value="B">B</option>,
            <option key="C" value="C">C</option>,
            <option key="E" value="E">E</option>,
            <option key="NT" value="NT">NT</option>
        ]

        let targetOptions31 = [
            <option key="" value=""></option>,
            <option key="A" value="A">A</option>,
            <option key="B" value="B">B</option>,
            <option key="C" value="C">C</option>,
            <option key="NT" value="NT">NT</option>
        ]


        let tgtOptions = [
            <option key="" value=""></option>,
            <option key="Y" value="Y">Y</option>,
            <option key="N" value="N">N</option>
        ]

        let exerciseTeam = (props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) ? adminExercise.TEAM : props.exercise.TEAM
        let targetOps = (exerciseTeam == 11 || exerciseTeam == 12) ? targetOptions11 : (exerciseTeam == 31 || exerciseTeam == 32) ? targetOptions31 : targetOptions21;


        let stateOptions = [<option key="000" value={''}></option>]
        for (let i = 0; i < Object.keys(states).length; i++) {
            stateOptions.push(<option key={Object.keys(states)[i]} value={Object.keys(states)[i]}>{Object.values(states)[i]}</option>)
        }

        let customerType = (props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31')) ? adminExercise.CUSTOMER_TYPE : props.exercise.CUSTOMER_TYPE

        const searchFields = (exerciseTeam == 31 || exerciseTeam == 32) ? [
            <Field key="FIRST_NAME" title="FIRST NAME" index={'FIRST_NAME'} value={searchParams} onChange={setSearchParams} />,
            <Field key="LAST_NAME" title="LAST NAME" index={'LAST_NAME'} value={searchParams} onChange={setSearchParams} />,
            <Field key="DEGREE" title="DEGREE" index={'DEGREE'} options={degreeOptions} value={searchParams} onChange={setSearchParams} />,
            <Field key="SPECIALTY" title="SPECIALTY" index={'SPECIALTY'} options={specialtyOptions} value={searchParams} onChange={setSearchParams} />,
            <Field key="CURRENT_TARGET_TYPE" title="CURRENT TARGET TYPE" index={'CURRENT_TARGET_TYPE'} options={targetOps} value={searchParams} onChange={setSearchParams} />,
            <Field key="CITY" title="CITY" index={'CITY'} value={searchParams} onChange={setSearchParams} />,
            <Field key="STATE" title="STATE" index={'STATE'} options={stateOptions} value={searchParams} onChange={setSearchParams} />
        ] :
            (exerciseTeam == 11)?
        [
            <Field key="FIRST_NAME" title="FIRST NAME" index={'FIRST_NAME'} value={searchParams} onChange={setSearchParams} />,
            <Field key="LAST_NAME" title="LAST NAME" index={'LAST_NAME'} value={searchParams} onChange={setSearchParams} />,
            <Field key="DEGREE" title="DEGREE" index={'DEGREE'} options={degreeOptions} value={searchParams} onChange={setSearchParams} />,
            <Field key="CURRENT_TARGET_TYPE" title="CURRENT TARGET TYPE" index={'CURRENT_TARGET_TYPE'} options={targetOps} value={searchParams} onChange={setSearchParams} />,
            <Field key="CITY" title="CITY" index={'CITY'} value={searchParams} onChange={setSearchParams} />,
            <Field key="STATE" title="STATE" index={'STATE'} options={stateOptions} value={searchParams} onChange={setSearchParams} />,
            <Field key="TGT" title="TARGET TYPE CHANGE" index={'TGT'} options={tgtOptions} value={searchParams} onChange={setSearchParams} />
        ]:
        [
            <Field key="FIRST_NAME" title="FIRST NAME" index={'FIRST_NAME'} value={searchParams} onChange={setSearchParams} />,
            <Field key="LAST_NAME" title="LAST NAME" index={'LAST_NAME'} value={searchParams} onChange={setSearchParams} />,
            <Field key="DEGREE" title="DEGREE" index={'DEGREE'} options={degreeOptions} value={searchParams} onChange={setSearchParams} />,
            <Field key="CURRENT_TARGET_TYPE" title="CURRENT TARGET TYPE" index={'CURRENT_TARGET_TYPE'} options={targetOps} value={searchParams} onChange={setSearchParams} />,
            <Field key="CITY" title="CITY" index={'CITY'} value={searchParams} onChange={setSearchParams} />,
            <Field key="STATE" title="STATE" index={'STATE'} options={stateOptions} value={searchParams} onChange={setSearchParams} />
            
        ]

        return (
            <div className={styles.search} style={props.preview ? {height: 'calc(100% - 50px)'} : undefined}>
                <TopBar {...props} config={config} data={data} terr={terrID} loaded={config && !loaded} searchParams={searchParams} backButtonUsers={["ADMIN", "OAS", "OBD", "DBD"]} name={adminExercise ? adminExercise.NAME : props.exercise.NAME} search={search} setData={setData} setTotalResults={setTotalResults} setLoaded={setLoaded} export={true} clear={clear}>
                    {searchFields}
                </TopBar>
                <StatusBar {...props} adminExercise={adminExercise} status={status} id={targetexid} team={exerciseTeam}  terr={terrID} getTotals={getTotals} />
                <div className={styles.results} ref={props.tableRef}>
                    <Table styles={[styles.table]}>
                        <Titles {...props} search={search} getTotals={getTotals} config={config} data={data} setData={setData} resetSort={resetSort} setResetSort={setResetSort} />
                        <Results {...props} adminExercise={adminExercise} status={status} id={targetexid} terr={terrID} search={search} getTotals={getTotals} idField={"PROF_ID"} result={customerType === "HCP" ? ResultHCP : ResultHCO} config={config} data={data} loaded={loaded} lock={props.approved || lock} currentPage={currentPage} resultsPerPage={resultsPerPage} options={options} />
                    </Table>
                </div>
                <Pagination {...props} currentPage={currentPage} setCurrentPage={setCurrentPage} resultsPerPage={resultsPerPage} setResultsPerPage={setResultsPerPage} totalResults={totalResults} setTotalResults={setTotalResults} setProperties={(newCurrentPage, newResultsPerPage) => {
                        if (newCurrentPage !== currentPage) {
                            setCurrentPage(newCurrentPage)
                        }
                        if (newResultsPerPage !== resultsPerPage) {
                            setResultsPerPage(newResultsPerPage)
                        }
                    }}/>
            </div>
        )
    }
}

export default Search
