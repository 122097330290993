import React, {Component} from 'react';
import styles from '../css/LeftMenu.module.css';

class LeftMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: props.active
        };
    }

    render() {
        return (
            <div className={styles.LeftMenu}>
                <div></div>
                <div  className={this.props.active === 0 ? styles.active : ""}  onClick={() => {
                        this.props.changeView(0)
                    }}>User Management</div>
                <div className={this.props.active === 1 ? styles.active : ""} onClick={() => {
                        this.props.changeView(1)
                    }}>Targeting Exercises HCP</div>
             
                <div className={this.props.active === 3 ? styles.active : ""} onClick={() => {
                        this.props.changeView(3)
                    }}>Best Address Exercises</div>
                <div className={this.props.active === 4 ? styles.active : ""} onClick={() => {
                        this.props.changeView(4)
                }} styles="cursor:pointer;">Regional Plan Exercises</div>
                <div className={this.props.active === 5 ? styles.active : ""} onClick={() => {
                    this.props.changeView(5)
                }} styles="cursor:pointer;">System Audit</div>
                {/*<div className={this.props.active === 6 ? styles.active : ""} onClick={() => {*/}
                {/*    this.props.changeView(6)*/}
                {/*}} styles="cursor:pointer;">Manage Exhibits & Displays</div>*/}
            </div>
        );
    }
}

export default LeftMenu;
