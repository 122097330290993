import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import Styles from './Navbar.module.css'
import logo from '../../assets/logo.png'
import {RootStoreContext} from "../../_stores/rootStore";

export default function Navbar(props) {
    const rootStore = useContext(RootStoreContext)
    const { user, doLogout, isAuthenticated, isExUsUser, isDermAdmin, IsEDAdmin, IsHemoncComp, IsDermComp, IsHemoncOps, IsDermOps, IsMarketAccessRole, IsOcneAdmin } = rootStore.userStore;

    let cog = ''
    let xus = ''
    let hcp = ''
    let hco = ''
    let rp = ''
    let targeting = []
    let bestAddress = ''
    let workflow = ''


    if (isAuthenticated && user) {
        if (user.role === 'ADMIN') {
            cog = (
                <Link to='/admin'>
                    <li key={99} className={props.location.pathname === '/admin'
                            ? [Styles.Active, Styles.cog].join(' ')
                            : Styles.cog} onClick={() => {
                            if (props.location.pathname === '/admin') {
                                window.location.reload();
                            }
                        }}>
                        <i className='fas fa-cog'></i>
                    </li>
                </Link>
            )
        }
        if ((user.role === 'ADMIN' || user.role === 'COMPLIANCE' || user.role !== 'ExUS') && ["81","82"].includes(user.team) == false) {
            hcp = (
                <Link to='/hcp'>
                    <li key={2} className={props.location.pathname === '/hcp' || props.location.pathname.includes('/hcp/details')
                        ? Styles.Active
                        : ''} onClick={() => {
                            if (props.location.pathname === '/hcp') {
                                window.location.reload();
                            }
                        }}>
                        <div className={Styles.Pages} onClick={() => { }}>Professionals</div>
                    </li>
                </Link>
            )
        }
        if (user.role === 'ADMIN' || user.role === 'COMPLIANCE' || user.role !== 'ExUS' && ["81", "82"].includes(user.team) == false) {
            hco = (
                <Link to='/hco'>
                    <li key={3} className={props.location.pathname === '/hco' || props.location.pathname.includes('/hco/details')
                        ? Styles.Active
                        : ''} onClick={() => {
                            if (props.location.pathname === '/hco') {
                                window.location.reload();
                            }
                        }}>
                        <div className={Styles.Pages} onClick={() => { }}>Organizations</div>
                    </li>
                </Link>
            )
        }
        if (user.role === 'ADMIN' || user.role === 'ExUS' || user.isExUsUser === 'true' || ["81", "82"].includes(user.team)) {
            xus = (
                <Link to='/xus'>
                    <li key={3} className={props.location.pathname === '/xus' || props.location.pathname.includes('/xus/details')
                        ? Styles.Active
                        : ''} onClick={() => {
                            if (props.location.pathname === '/xus') {
                                window.location.reload();
                            }
                        }}>
                        <div className={Styles.Pages} onClick={() => { }}>Ex-US Professionals</div>
                    </li>
                </Link>
            )
        }
        if (props.exercises) {
            if (props.exercises.regionalPlan) {
                rp = (
                    <Link to={`/regionalplan/${props.exercises.regionalPlan.REGIONAL_PLAN_ID}`}>
                        <li key={props.exercises.regionalPlan.REGIONAL_PLAN_ID} className={props.location.pathname.includes('/regionalplan')
                            ? Styles.Active
                            : ""} onClick={() => {
                                if (props.location.pathname.includes('/regionalplan')) {
                                    window.location.reload();
                                }
                            }}>
                            <div className={Styles.Pages}>Regional Plan</div>
                        </li>
                    </Link>
                )
            }
          


            if (props.exercises.targeting && (user.role !== "ADMIN" && (user.role !== 'DBD' && user.team !== 31))) {

                console.log('f');


                targeting = (
                    <Link to={`/targeting`}>
                        <li key={props.exercises.targeting.ID} className={props.location.pathname.includes('/targeting')
                            ? Styles.Active
                            : ""} onClick={() => {
                                if (props.location.pathname.includes('/targeting')) {
                                    window.location.reload();
                                }
                            }}>
                            <div className={Styles.Pages}>Targeting Exercise</div>
                        </li>
                    </Link>
                )
            }
            if (props.exercises.targeting && (user.role === "ADMIN" || (user.role == 'DBD' && user.team == 31))) {

                

                for (let i = 0; i < props.exercises.targeting.length; i++) {

                    targeting.push(
                        (
                            <Link key={props.exercises.targeting[i].ID} to={`/targeting/admin/${props.exercises.targeting[i].ID}`}>
                                <li key={props.exercises.targeting[i].ID} className={props.location.pathname.includes(`/targeting/admin/${props.exercises.targeting[i].ID}`)
                                    ? Styles.Active
                                    : ""} onClick={() => {
                                        window.location.replace(`/targeting/admin/${props.exercises.targeting[i].ID}`);
                                    }}>
                                    <div className={Styles.Pages}><i className="fas fa-bullseye"></i>&nbsp;{props.exercises.targeting[i].NAME}</div>
                                </li>
                            </Link>
                        )
                    )
                }

            }
            if (props.exercises.bestAddress) {
                bestAddress = (
                    <Link to={`/bestaddress`}>
                        <li key={5} className={props.location.pathname.includes('/bestaddress')
                            ? Styles.Active
                            : ""} onClick={() => {
                                if (props.location.pathname.includes('/bestaddress')) {
                                    window.location.reload();
                                }
                            }}>
                            <div className={Styles.Pages}>Best Address Exercise</div>
                        </li>
                    </Link>
                )
            }
        }
        if ((user.role == 'OCNE' || user.role == 'ADMIN' || user.IsHemoncComp == "true" || user.IsDermComp == "true" || user.IsDermOps == "true" || user.IsHemoncOps == "true" || user.IsEDAdmin == true || user.role === 'OHS' || user.role === 'OBD' || user.role === 'DTS' || user.role === 'DBD' || user.role === 'ASD' || user.role === 'ABD' || user.role === 'VP' || user.IsMarketAccessRole == "true") && ["81", "82"].includes(user.team) == false) {

            workflow = (
                <Link to='/workflow'>
                    <li key={4} className={''} onClick={() => {
                        if (props.location.pathname === '/workflow') {
                            window.location.reload();
                        }
                    }}>
                        <div className={Styles.Pages} onClick={() => { }}>Exhibits & Displays</div>
                    </li>
                </Link>
            )
        } else {
            workflow = '';
        }
    }
    return (
        !isAuthenticated && !user ? <div></div> :
        <div className={Styles.NavBar}>
            <div className={Styles.Wrapper}>
                <img className={Styles.Logo} src={logo} alt=''/>
            </div>
            <ul>
                <Link to='/'>
                    <li key={1} className={props.location.pathname === '/'
                            ? Styles.Active
                            : ''} onClick={() => {
                            if (props.location.pathname === '/') {
                                window.location.reload();
                            }
                        }}>
                        <div className={Styles.Pages} onClick={() => {}}>Home</div>
                    </li>
                </Link>
                    {hcp}
                    {hco}
                    {xus}
                    {rp}
                    {targeting}
                    {bestAddress}
                    {cog}
                    {workflow}
                <li key={4} className={Styles.cog} onClick={() => {
                        let callback
                        callback = window.location.href = '/authentication/logout';
                        doLogout(callback)
                    }}>
                    <div>Logout</div>
                </li>
                <div className={Styles.cog}>{user.userName}</div>
            </ul>
        </div>
    )
}