import React, {Component} from 'react';
import styles from './css/Admin.module.css';
import TopBar from './components/TopBar';
import LeftMenu from './components/LeftMenu';
import ResultsView from './components/ResultsView';
import BottomBar from './components/BottomBar';
import DialogBoxAddUser from './components/DialogBoxAddUser';
import DialogBoxAddUserConfirmation from './components/DialogBoxAddUserConfirmation';
import DialogBoxEditUser from './components/DialogBoxEditUser';
import DialogBoxEditUserActivate from './components/DialogBoxEditUserActivate';
import DialogBoxEditUserDeactivate from './components/DialogBoxEditUserDeactivate';
import DialogBoxEditUserPasswordReset from './components/DialogBoxEditUserPasswordReset';
import RegionalPlan from './components/RegionalPlan';
import AdminRegionalAdd from './components/AdminRegionalAdd';
import AdminRegionalDelete from './components/AdminRegionalDelete';
import Popup from '../../globalcomponents/Popup';
import AdminExerciseTemplate from '../../components/AdminExerciseTemplate'
import TargetingChangeWidth from '../Targeting/Search/Search'
import BestAddressChangeWidth from '../BestAddress/Search/Search'
import targetingDefaultColumnsHCP from '../../lookup/targetingDefaultColumnsHCP.json'
import targetingDefaultColumnsHCO from '../../lookup/targetingDefaultColumnsHCO.json'
import bestAddressDefaultColumns from '../../lookup/bestAddressDefaultColumns.json'
import AuditTrail from './components/AuditTrail';
import AdminWorkflow from './components/AdminWorkflow';

class Admin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ViewValue: 0,
            popup: false,
            Views: [],
            activeView: 0,
            ResultsPerPage: 20,
            CurrentPage: 0,
            data: {},
            orgData: {},
            confirmationData: {},
            recordIndex: -1,
            userId: -1,
            wizard: false,
            regionalPlanData : []

        }
    }

    changeAdminView(i, data, num, id, wizard) {
        if (i === -1) {
            this.setState({
                ViewValue: 0,
                popup: false,
                wizard: wizard
            });
        }
        else {
            if (data !== undefined) {
                this.setState({
                    ViewValue: i,
                    popup: true,
                    confirmationData: data,
                    wizard: wizard
                });
            }
            if (num >= 0) {
                this.setState({
                    ViewValue: i,
                    popup: true,
                    recordIndex: num,
                    wizard: wizard
                });
            }
            if (id > 0) {
                this.setState({
                    ViewValue: i,
                    popup: true,
                    userId: id,
                    wizard: wizard
                });
            }
            else {
                this.setState({
                    ViewValue: i,
                    popup: true,
                    wizard: wizard
                });
            }
        }
    }

    setPageViewCount(ResultsPerPage, CurrentPage) {
        this.setState({
            ResultsPerPage: ResultsPerPage,
            CurrentPage: CurrentPage
        })
        this.buildResults(this.state.data, ResultsPerPage, CurrentPage)
    }

    setPage(CurrentPage) {
        this.setState({CurrentPage: CurrentPage})
        this.buildResults(this.state.data, this.state.ResultsPerPage, CurrentPage)
    }

    setData(d) {
        this.setState({
            data: d,
            CurrentPage: 0
        });
        this.buildResults(d, this.state.ResultsPerPage, this.state.CurrentPage)
    }

    setOrgData(d) {
        this.setState({
            orgData: d,
        });
    }

    componentDidMount() {
        this.buildResults(this.state.data, this.state.ResultsPerPage, this.state.CurrentPage)
    }

    buildResults(data, ResultsPerPage, CurrentPage) {
        let results = [
            <div key={0} className={styles.results}>
                <ResultsView changeAdminView={this.changeAdminView.bind(this)} setData={this.setData.bind(this)} data={data} ResultsPerPage={ResultsPerPage}
                CurrentPage={CurrentPage} />
            </div>,
            <div key={1} className={styles.bottomBar}>
                <BottomBar data={data} setPageViewCount={this.setPageViewCount.bind(this)} setPage={this.setPage.bind(this)} CurrentPage={this.state.CurrentPage}/>
            </div>
        ]
        this.setState({Views: [results]})
    }

    changeView(i) {
        this.setState({activeView: i});
    }

    close() {
        this.setState({
            popup: false
        });
    }

    updateRegionalPlanData(data) {
        this.setState({
            regionalPlanData: data
        });
    }

    render() {
        let DialogBoxes = [<DialogBoxAddUser user={this.props.user} setData={this.setData.bind(this)} changeAdminView={this.changeAdminView.bind(this)}/>,
            <DialogBoxAddUserConfirmation user={this.props.user} changeAdminView={this.changeAdminView.bind(this)} confirmationData={this.state.confirmationData}/>,
            <DialogBoxEditUser user={this.props.user} changeAdminView={this.changeAdminView.bind(this)} setData={this.setData.bind(this)} data={this.state.data} recordIndex={this.state.recordIndex} userId={this.state.userId}/>,
            <DialogBoxEditUserActivate wizard={this.state.wizard} user={this.props.user} changeAdminView={this.changeAdminView.bind(this)} setData={this.setData.bind(this)} data={this.state.data} recordIndex={this.state.recordIndex} userId={this.state.userId}/>,
            <DialogBoxEditUserDeactivate wizard={this.state.wizard} user={this.props.user} changeAdminView={this.changeAdminView.bind(this)} setData={this.setData.bind(this)} data={this.state.data} recordIndex={this.state.recordIndex} userId={this.state.userId}/>,
            <DialogBoxEditUserPasswordReset user={this.props.user} changeAdminView={this.changeAdminView.bind(this)} setData={this.setData.bind(this)} data={this.state.data} recordIndex={this.state.recordIndex} userId={this.state.userId}/>,
            <AdminRegionalAdd close={this.close.bind(this)} setData={this.updateRegionalPlanData.bind(this)}/>,
            <AdminRegionalAdd close={this.close.bind(this)} setData={this.updateRegionalPlanData.bind(this)} data={this.state.confirmationData} />,
            <AdminRegionalDelete close={this.close.bind(this)} data={this.state.confirmationData} setData={this.updateRegionalPlanData.bind(this)}/>
        ]

        let results = [
            <ResultsView key={0} changeAdminView={this.changeAdminView.bind(this)} setData={this.setData.bind(this)} data={this.state.data} ResultsPerPage={this.state.ResultsPerPage}
            CurrentPage={this.state.CurrentPage} orgData={this.state.orgData} setOrgData={this.setOrgData.bind(this)} />,
            <div key={1} className={styles.bottomBar}>
                <BottomBar data={this.state.data} setPageViewCount={this.setPageViewCount.bind(this)} setPage={this.setPage.bind(this)} CurrentPage={this.state.CurrentPage}/>
            </div>
        ]

        let view = [
            results,
            <AdminExerciseTemplate key='HCPTARGETING' user={this.props.user} title={"HCP Targeting Exercises"} ChangeWidth={TargetingChangeWidth} EXERCISE_ID_KEY={'TARGETING_EXERCISE_ID'} defaultColumns={targetingDefaultColumnsHCP} moreButtons={true} type="HCP" endpoints={{
                getCustomColumns: '/api/admin/targetingexercise/get-custom-columns',
                addExercise: '/api/admin/targetingexercise/add-exercise',
                getExercise: '/api/admin/targetingexercise/get-exercise',
                getExercises: '/api/admin/targetingexercise/get-exercises',
                deleteExercise: '/api/admin/targetingexercise/delete-exercise',
                editExercise: '/api/admin/targetingexercise/edit-exercise',
                editExerciseWidth:'/api/admin/targetingexercise/edit-exercise-width',
                setDemotionReasons: '/api/admin/targetingexercise/set-demotion-reasons',
                getDemotionReasons: '/api/admin/targetingexercise/get-demotion-reasons'

            }} />,
            <AdminExerciseTemplate key='HCOTARGETING' user={this.props.user} title={"HCO Targeting Exercises"} ChangeWidth={TargetingChangeWidth} EXERCISE_ID_KEY={'TARGETING_EXERCISE_ID'} defaultColumns={targetingDefaultColumnsHCO} moreButtons={true} type="HCO" endpoints={{
                getCustomColumns: '/api/admin/targetingexercise/get-custom-columns',
                addExercise: '/api/admin/targetingexercise/add-exercise',
                getExercise: '/api/admin/targetingexercise/get-exercise',
                getExercises: '/api/admin/targetingexercise/get-exercises',
                deleteExercise: '/api/admin/targetingexercise/delete-exercise',
                editExercise: '/api/admin/targetingexercise/edit-exercise',
                editExerciseWidth:'/api/admin/targetingexercise/edit-exercise-width',
                setDemotionReasons: '/api/admin/targetingexercise/set-demotion-reasons',
                getDemotionReasons: '/api/admin/targetingexercise/get-demotion-reasons'
            }} />,

            <AdminExerciseTemplate key='BEST_ADDRESS' user={this.props.user} title={"Best Address Exercises"} ChangeWidth={BestAddressChangeWidth} EXERCISE_ID_KEY={'BEST_ADDRESS_EXERCISE_ID'} defaultColumns={bestAddressDefaultColumns} moreButtons={true} endpoints={{
                getCustomColumns: '/api/admin/bestaddressexercise/get-custom-columns',
                addExercise: '/api/admin/bestaddressexercise/add-exercise',
                getExercise: '/api/admin/bestaddressexercise/get-exercise',
                getExercises: '/api/admin/bestaddressexercise/get-exercises',
                deleteExercise: '/api/admin/bestaddressexercise/delete-exercise',
                editExercise: '/api/admin/bestaddressexercise/edit-exercise',
                editExerciseWidth:'/api/admin/bestaddressexercise/edit-exercise-width',
                setDemotionReasons: '/api/admin/bestaddressexercise/set-demotion-reasons',
                getDemotionReasons: '/api/admin/bestaddressexercise/get-demotion-reasons'
            }} />,
            <RegionalPlan key='REGIONAL_PLAN' changeAdminView={this.changeAdminView.bind(this)} data={this.state.regionalPlanData} setData={this.updateRegionalPlanData.bind(this)}/>,
            <AuditTrail key='AUDIT_TRAIL' changeAdminView={this.changeAdminView.bind(this)} data={this.state.regionalPlanData} />,
            /* <AdminWorkflow key='ADMIN_WORKFLOW' changeAdminView={this.changeAdminView.bind(this)} />*/
        ]
        return (
            <div>
                {this.state.popup ? (<Popup component={<div className={styles.editScreen}>{DialogBoxes[this.state.ViewValue]}</div>} />) : ""}
                <div className={styles.Admin}>
                    <div className={styles.topBar}>
                        <TopBar />
                    </div>
                    <div className={styles.mainView}>
                        <div className={styles.menu}>
                            <LeftMenu changeView={this.changeView.bind(this)} active={this.state.activeView}/>
                        </div>
                        <div key={0} className={styles.results}>
                            {view[this.state.activeView]}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Admin;
